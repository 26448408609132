import request from '@/utils/request'

// 获取沉默期即将到期卡列表
export function findCardSilentManagesUnexpired(params) {
  return request({
    url: `/card_silent_manages/unexpired`,
    method: 'get',
    params
  })
}

// 导出沉默期即将到期的卡
export function exportCardSilentManagesUnexpired(data) {
  return request({
    url: `/card_silent_manages/unexpired/export`,
    method: 'post',
    data
  })
}

// 获取沉默期即将到期卡列表
export function findCardSilentManagesExpired(params) {
  return request({
    url: `/card_silent_manages/expired`,
    method: 'get',
    params
  })
}

// 导出沉默期已到期的卡
export function exportCardSilentManagesExpired(data) {
  return request({
    url: `/card_silent_manages/expired/export`,
    method: 'post',
    data
  })
}
